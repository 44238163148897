var popup = {
    close: function () {
        $('.popup').fadeOut().removeClass('js-isShowed');
        bodyScrollLock.clearAllBodyScrollLocks();
    },
    open: function ($obj) {
        bodyScrollLock.disableBodyScroll($obj.find('.popup__body'),{
            allowTouchMove: el => {
                while (el && el !== document.body) {
                    if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
                        return true
                    }

                    el = el.parentNode
                }
            }
        });
        $obj.fadeToggle().toggleClass('js-isShowed');
    },
    toggle: function (obj) {
        var $obj = $(obj);
        if($obj.hasClass('js-isShowed')){
            this.close();
        }else{
            this.open($obj);
        }
    }
};
$(function () {

    $('[data-modal]').click(function () {
        if ($('.' + $(this).data('modal')).length) {
            popup.close();
            popup.toggle('.' + $(this).data('modal'));
        }
    });
    $('.js-closePopup').click(function () {
        popup.close();
    });

    var handleOverlayClose = function (evt) {
        var target = evt.target;
        if ($('.js-isShowed').length > 0) {
            if (target !== document.querySelector('.popup__body') && target === document.querySelector('.js-isShowed').querySelector('.popup__overlay')) {
                popup.close();
            }
        }

    };
    $('.js-watchPopup').click(function (evt) {
        handleOverlayClose(evt);
    });
});

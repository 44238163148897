(function () {
    var jsGetCookie = function (name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    var jsSetCookie = function (name, value, options) {

        options = options || {};

        var expires = options.expires;
        if (typeof(expires) == "number" && expires)
        {
            var currentDate = new Date();
            currentDate.setTime(currentDate.getTime() + expires * 1000);
            expires = options.expires = currentDate;
        }

        if (expires && expires.toUTCString)
        {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propertyName in options)
        {
            if (!options.hasOwnProperty(propertyName))
            {
                continue;
            }
            updatedCookie += "; " + propertyName;
            var propertyValue = options[propertyName];
            if (propertyValue !== true)
            {
                updatedCookie += "=" + propertyValue;
            }
        }

        document.cookie = updatedCookie;

        return true;
    }
    document.addEventListener('DOMContentLoaded', function () {
        var getCookie = jsGetCookie('checkcookie');

        if (!getCookie) {
            var element = document.createElement('div');
            element.classList.add('checkcookie');

            var container = document.createElement('div');
            container.classList.add('container');

            var wrapper = document.createElement('div');
            wrapper.classList.add('checkcookie__wrapper');

            var buttonYES = document.createElement('button');
            buttonYES.classList.add('checkcookie-button');

            buttonYES.textContent = 'OK';
            buttonYES.addEventListener('click', function () {
                jsSetCookie('checkcookie', '1', {expires: 97200, path: '/'});
                document.querySelector('.checkcookie').remove();
            });

            var text = document.createElement('div');
            text.classList.add('checkcookie-text');
            text.textContent = 'Мы используем файлы cookie для оптимизации работы сайта и получения статистики. Мы также используем сторонние файлы cookie от наших партнеров \n' +
                'в маркетинговых и аналитических целях. Продолжая навигацию по сайту, вы соглашаетесь на использование файлов cookie. Чтобы узнать подробнее о файлах cookie и способах управления ими, пожалуйста, ознакомьтесь с нашей Политикой конфиденциальности.';

            wrapper.appendChild(text);
            wrapper.appendChild(buttonYES);
            container.appendChild(wrapper);
            element.appendChild(container);
            document.body.appendChild(element);
        }
    })
})();

const menu = {
    $burger: $('.header-burger'),
    $menu: $('.header-mobile'),
    $closeButton: $('.header-mobile__close-button'),
    $link: $('.header-mobile__link_blank'),
    opened: false,
    open: function () {
        if (!this.opened) {
            this.$burger.addClass('header-burger_open');
            this.$menu.addClass('header-mobile_open');
            this.opened = true
        }
    },
    close: function () {
        if (this.opened) {
            this.$burger.removeClass('header-burger_open');
            this.$menu.removeClass('header-mobile_open');
            this.opened = false
        }
    },
    toggle: function () {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    },
    handleClose: function (event) {
        let target = event.target;
        console.log(event);
        if (target === document.querySelector('.header-mobile') && this.opened) {
            this.close();
        }
    },
    init: function () {
        let _this = this;
        // this.initTimeline()
        this.$burger.click(function () {
            _this.toggle();
        });
        this.$closeButton.click(function () {
            _this.close();
        });
        this.$link.click(function () {
            _this.close();
        });
        this.$menu.click(function (event) {
            _this.handleClose(event);
        });

        $('.header-mobile__list li').click(function (e) {
            if($(window).width() <= 1024){
                var submenu = $(this).find('ul');
                if(submenu.length > 0){
                    if(!$(this).hasClass('is-open')){
                        $(this).addClass('is-open');
                        e.preventDefault();
                        return false;
                    }
                }
            }

        });

        $(document).keyup(function (e) {
            if (e.key === "Escape") {
                _this.close();
            }
        })
    }
};

export default menu;

import $ from 'jquery';

import './popup'
import './cookie'

import menu from './menu';
import bottomMenu from './bottom-menu';

($(function () {
    bottomMenu.init();
}));

($(function () {

    menu.init();


    $('.select-unit').selectric({
            arrowButtonMarkup: '',
        }
    );

    $('.service-background').click(function () {
        $('.button-background').toggleClass('button-background_black');
        $('.select-background').toggleClass('select-background_black');
    });
}));

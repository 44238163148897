const bottomMenu = {
    $button: $('.service-button-toggle'),
    $menu: $('.service-menu'),
    opened: false,
    open: function () {
        if (!this.opened) {
            this.$button.addClass('service-button_open');
            this.$menu.addClass('service-menu_open');
            this.opened = true
        }
    },
    close: function () {
        if (this.opened) {
            this.$button.removeClass('service-button_open');
            this.$menu.removeClass('service-menu_open');
            this.opened = false
        }
    },
    toggle: function () {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    },

    handleClose: function (event) {
        let target = event.target;
        console.log(event);
        if (target === document.querySelector('.service-menu') && this.opened) {
            this.close();
        }
    },

    init: function () {
        let _this = this;
        // this.initTimeline()
        this.$button.click(function () {
            _this.toggle();
        });
        this.$menu.click(function (event) {
            _this.handleClose(event);
        });

        $(document).keyup(function (e) {
            if (e.key === "Escape") {
                _this.close();
            }
        })
    }
};
export default bottomMenu;
